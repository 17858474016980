<template>
  <div>

    <b-card>
      <b-button v-for="x in parameter.agentList" :key="x.code"
        :variant="parameter.selectedAgent == x ? `danger` : `outline-danger`" class="mr-1"
        @click="parameter.selectedAgent = x">
        {{ x.description }}
      </b-button>
    </b-card>

    <b-card>
      <template #header>
        <h5 v-if="parameter.selectedAgent">Showing Client of : <b>{{ parameter.selectedAgent.description }}</b></h5>
        <b-button variant="primary" class="ml-auto" size="sm" @click.stop="showDetail()">New Client</b-button>
      </template>

      <template #default>
        <vue-element-loading :active="loader" />
        <b-table :items="table.items" :fields="table.fields" :per-page="table.paging.pageSize" show-empty striped
          :current-page="table.paging.pageNo">
          <template #cell(contact)="data">
            <div class="d-flex">
              <span>Whatsapp</span>
              <b-link v-if="data.item.whatsapp" class="ml-auto" :href="`https://wa.me/${data.item.whatsapp}`"
                target="_blank">
                {{ data.item.whatsapp }}
              </b-link>
              <span v-else class="ml-auto">~</span>
            </div>
            <div class="d-flex">
              <span>Telegram</span>
              <b-link v-if="data.item.telegramUser" class="ml-auto" :href="`https://t.me/${data.item.telegramUser}`"
                target="_blank">
                {{ data.item.telegramUser }}
              </b-link>
              <span v-else class="ml-auto">~</span>
            </div>
          </template>
          <template #cell(action)="data">
            <b-button variant="warning" size="sm" @click.stop="showDetail(data.item)">Edit
            </b-button>
            <b-button variant="danger" size="sm" class="ml-1" @click.stop="remove(data.item)">
              Delete
            </b-button>
          </template>
        </b-table>
      </template>

      <template #footer>
        <div class="d-flex mt-auto align-items-center">
          <page-info :page-no="table.paging.pageNo" :page-size="table.paging.pageSize" :total="table.items.length" />
          <b-pagination v-model="table.paging.pageNo" :totalRows="table.items.length" :per-page="table.paging.pageSize"
            align="center" class="ml-auto">
          </b-pagination>
        </div>
      </template>


    </b-card>

    <b-modal v-if="parameter.selectedAgent" v-model="modal.detail" no-close-on-backdrop lazy centered hide-footer
      @hidden="selectedRow = null">
      <client-detail :agentCode="parameter.selectedAgent.code" :client="selectedRow" @onSaveSuccess="saveSuccess"
        @onClose="cancel" />
    </b-modal>


  </div>
</template>

<script>
import ClientDetail from "./Detail.vue";
import api from "@api";

export default {
  name: `ClientList`,
  components: { ClientDetail },
  data() {
    return {
      parameter: {
        agentList: [],
        selectedAgent: null,
      },
      selectedRow: null,
      table: {
        items: [],
        fields: [
          { key: `contactName`, label: `Contact Name`, sortable: true },
          { key: `contact`, label: `Contact`, },
          { key: `action`, label: ``, class: `text-right` },
        ],
        paging: {
          pageNo: 1,
          pageSize: 10,
        },
      },
      modal: { detail: false },
      loader: false,
    };
  },

  computed: {
  },
  watch: {
    "parameter.selectedAgent": function () {
      this.loadData();
    },
  },
  async mounted() {
    await this.getAgentList();
  },
  methods: {
    async getAgentList() {
      this.loader = true;
      try {
        const response = await api.get(`agent`);
        this.parameter.agentList = response;
        this.parameter.selectedAgent = response[0];
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async loadData() {
      this.loader = true;
      try {
        this.table.items = await api.get(`client`, { agentCode: this.parameter.selectedAgent.code });
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    showDetail(row) {
      if (row) this.selectedRow = { ...row };
      this.modal.detail = true;
    },
    saveSuccess() {
      this.loadData();
      this.modal.detail = false;
    },
    cancel() {
      this.modal.detail = false;
    },
    async remove(rec) {
      let confirmed = await this.showConfirm(`Are you sure want to delete this record?`);
      if (!confirmed) return;

      this.loader = true;

      try {
        await api.delete(`client/${rec.code}`);
        this.loadData();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }

    },
  },
};
</script>
